<template>
  <div>
    <div class="btn btn-block btn-secondary text-center btn-sm mb-2">
      CONTROL DE HERRAMIENTAS
    </div>
    <pre-tests
      :details="reg.details || details"
      :disabled="true"
      :treatment_plan_id="treatment_plan_id"
    ></pre-tests>
    <div class="btn btn-block btn-secondary text-center btn-sm mb-2">
      ETAPA ACTUAL
    </div>
    <select-state v-model="reg.state" class="mb-2"></select-state>

    <!-- <div class="btn btn-block btn-secondary text-center btn-sm mb-2">
      INICIO
    </div>
    <table class="table table-bordered table-sm">
      <tr>
        <th>FECHA</th>
        <th>DETALLE</th>
        <th>OBSERVACIONES</th>
      </tr>
      <tr>
        <td class="p-0">
          <app-datepicker v-model="adds.inicio[1]"></app-datepicker>
        </td>
        <td>Registro de Mordida y Separadores Superiores</td>
        <td></td>
      </tr>
      <tr>
        <td class="p-0">
          <app-datepicker v-model="adds.inicio[2]"></app-datepicker>
        </td>
        <td>Instalacion de Aparatos:</td>
        <td></td>
      </tr>
      <tr>
        <td class="p-0">
          <app-datepicker v-model="adds.inicio[3]"></app-datepicker>
        </td>
        <td>
          Pegado de Bandas, Tubos y Brackets Superiores y separadores Inferiores
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="p-0">
          <app-datepicker v-model="adds.inicio[4]"></app-datepicker>
        </td>
        <td>Pegado de Bandas, Tubos y Brackets Inferiores:</td>
        <td></td>
      </tr>
    </table> -->

    <div class="btn btn-block btn-secondary text-center btn-sm mb-2">
      SEGUIMIENTO DE TRATAMIENTO PACIENTE ACTIVO
    </div>
    <table class="table table-bordered">
      <tr>
        <th>FECHA</th>
        <th>CITAS</th>
        <th>ASISTENCIAS</th>
      </tr>
      <tr
        v-for="(l, i) in reg.times"
        :key="i"
        :class="{
          'table-warning': (i + 1) % 3 == 0
        }"
      >
        <td>{{ l.semana | dateFormat }}</td>
        <td>{{ l.citas }}</td>
        <td>{{ l.asistencias }}</td>
      </tr>
    </table>

    <!-- <pre>{{ reg }} </pre> -->
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import PreTests from "./PreTests";
import selectState from "./select-state";

const modelAdds = {
  inicio: {}
};

export default {
  components: {
    PreTests,
    selectState
  },

  // directives
  // filters

  props: {
    treatment_plan_id: {}
  },

  data: () => ({
    reg: {},
    duration: null,
    details: [],
    adds: modelAdds
  }),

  computed: {},

  watch: {
    //
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      // DentalClinicService.getTreatmentPlanDetails({
      //   treatment_plan_id: this.treatment_plan_id
      // }).then(res => {
      //   this.details = res;
      // });

      DentalClinicService.getOrthodontic({
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        this.duration = res.duration;
        this.reg = res;
        this.adds = res.adds || modelAdds;
      });
    }
  }
};
</script>

<style></style>
