<template>
  <select v-model="valueChild" class="custom-select">
    <option v-for="l in optionState" :key="l.value" :value="l.value">{{
      l.text
    }}</option>
  </select>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {}
  },

  data: () => ({
    optionState: [
      { text: "INICIO", value: 1 },
      { text: "TRATAMIENTO", value: 2 },
      { text: "CIERRE", value: 3 },
      { text: "CONTROL", value: 4 }
    ]
  }),

  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
